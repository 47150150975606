import UsersViewState from './UsersViewState';
import UsersViewProps from './UsersViewProps';
import { ContentViewModel, ContentView, Application, NavigationManager, Managers } from '@levelapp/softfabric';
import UsersOperation from '../../../../../Transfer/UsersOperation';
import * as XLSX from 'xlsx';
import { Text, View, TouchableWithoutFeedback, Image } from 'react-native';
import React from 'react';
import UsersViewStyle from './UsersViewStyle';
import StatusOperation from '../../../../../Transfer/StatusOperations';
import StateManager from '../../../../../Common/Managers/StateManager';
import StateManagerConst from '../../../../../Common/Managers/StateManagerConst';
import ExtendedManagers from '../../../../../Common/Managers/ExtendedManagers';
import RoleManager from '../../../../../Common/Managers/RolesManager';
import CheckIdentityValidityPopupView from './modules/checkidentityvaliditypopup/CheckIdentityValidityPopupView';
import Helpers from '../../../../../Components/Helpers/Helpers';

export default class UsersViewModel extends ContentViewModel<UsersViewState, UsersViewProps, UsersViewStyle>
{
    NavigationManager: NavigationManager = Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER);
    RoleManager: RoleManager = Application.current.resolve<RoleManager>(ExtendedManagers.ROLE_MANAGER)
    StateManager: StateManager = Application.current.resolve<StateManager>("STATE_MANAGER");
    usersOperation = new UsersOperation();
    textResearch: string = "";
    interval: any;


    constructor(props: ContentView) {
        super(props);
        let previousState = this.StateManager.retrieveState(StateManagerConst.USER);
        this.interval = null;


        /* Initial State */
        this.initialState({
            statuses: [],
            selectedStatus: previousState != undefined ? previousState.selectedStatus : { id: 0, title: "ALL", users: null },
            resetStepView: false,
            rows: [],
            initialRow: [],
            initialData: [],
            tableHeaders: [],
            isLoading: true,
            users: [],
            datas: [],
            text: previousState != undefined ? previousState.text : '',
            nbPage: 0,
            currentPage: 1,
            isResearching: false,
            isExporting: false,
            isExport: this.RoleManager.isExport,
            isAdmin: this.RoleManager.isAdmin,
            isCheckingValidty: false,
            exportPage: 1,
            vbexport: false,
            exportPercent: "0"
        });

        if (previousState != undefined) {
            this.textResearch = previousState.text;
        }

        /* BINDINGS */
        this.editField = this.editField.bind(this);
        this.handleSuccessUserCallback = this.handleSuccessUserCallback.bind(this);
        this.exportUsers = this.exportUsers.bind(this);
        this.handleResetStepView = this.handleResetStepView.bind(this);
        this.setStatuses = this.setStatuses.bind(this);
        this.handleStatusChanged = this.handleStatusChanged.bind(this);
        this.resetResearch = this.resetResearch.bind(this);
        this.pushRows = this.pushRows.bind(this);
        this.resetTable = this.resetTable.bind(this);
        this.refresh = this.refresh.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.checkIdentityValidity = this.checkIdentityValidity.bind(this);
        this.exportNonAvertisUsers = this.exportNonAvertisUsers.bind(this);
    }

    componentDidMount() {
        this.refresh();
        this.setStatuses();
        if (Helpers.getQueryVariable('id')) {
            this.usersOperation.getUserById(Helpers.getQueryVariable('id'), this.editField);
        }
    }

    refresh() {
        this.setField({ isLoading: true });
        let pageNumber = localStorage.getItem("userPage");
        if (pageNumber) {
            this.setField({ currentPage: +pageNumber })
            this.usersOperation.getSearchPagination(this.state().selectedStatus.title, +pageNumber, 20, this.textResearch, true, this.handleSuccessUserCallback);
        }
        else
            this.usersOperation.getSearchPagination(this.state().selectedStatus.title, this.state().currentPage, 20, this.textResearch, true, this.handleSuccessUserCallback);
    }

    setStatuses() {
        let statuses: any[] = [];
        statuses.push({ id: 0, title: "ALL", users: null });
        new StatusOperation().getStatuses("P", (statusList: any) => {
            statusList.forEach((status: any) => {
                statuses.push(status);
            });
            var ordering = {} as any, // map for efficient lookup of sortIndex
                sortOrder = ['ALL', 'NEW', 'PENDING', 'FIELDS TO VERIFY', 'DOC NON CONFORME', 'ID UPLOADED', 'POD UPLOADED', 'POB UPLOADED', 'VALIDATED TO VERIFY', 'VALIDATED', 'ID EXPIRED', 'DOC MISSING - ALL', 'DOC MISSING - ID', 'DOC MISSING - POD', 'DOC MISSING - POB', 'BLOCKED', 'REFUSED'];
            for (var i = 0; i < sortOrder.length; i++) {
                ordering[sortOrder[i]] = i;
            }
            statuses.sort(function (a, b) {
                return (ordering[a.title] - ordering[b.title]) || a.title.localeCompare(b.title);
            });
            statuses = statuses.filter(x => x.title != "PENDING - ID");
            this.setField({ statuses: statuses });
        });
    }

    handleSuccessUserCallback(ret: any) {
        let users: any[] = ret.objects;
        let datas: any[] = [];
        let rows: any[] = [];

        if (ret.totalPage == 0) {
            this.setField({ datas: [] });
            this.setField({ rows: [] });
            this.setField({ initialData: [] });
            this.setField({ initialRow: [] });
            this.setField({ tableHeaders: ['ID', 'NUMBER', 'DATE CREATION', 'NOMS', 'MAIL', 'STATUS', 'MODIFICATION', 'EDIT'] });
            this.setField({ users: undefined });
            this.setField({ isLoading: false });
            this.setField({ nbPage: ret.totalPage });
            return;
        }

        users.forEach((user) => {
            let userDTO = {
                "id": user.id,
                "creationDate": user.formCompletionDate,
                "name": user.firstName + " " + user.lastName,
                "clientNumber": user.clientNumber,
                "email": user.email,
                "userStatusTitle": user.userStatus.title
            }

            var date = new Date(user.formCompletionDate);
            var dateAsString = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " ";
            //date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

            let lastModificationDate = user.lastModificationDate;
            if (user.formCompletionDate > lastModificationDate)
                lastModificationDate = user.formCompletionDate;
            if (user.documentsUploadDate > lastModificationDate)
                lastModificationDate = user.documentsUploadDate

            var modifDate = new Date(lastModificationDate);
            var modifDateAsString = modifDate.getDate() + "-" + (modifDate.getMonth() + 1) + "-" + modifDate.getFullYear() + " ";
            //modifDate.getHours() + ":" + modifDate.getMinutes() + ":" + modifDate.getSeconds();

            datas.push(userDTO);
            rows.push(
                <View>
                    <Text style={this.style().text}>{userDTO.id}</Text>
                    <Text style={this.style().text}>{userDTO.clientNumber}</Text>
                    <Text style={this.style().text}>{dateAsString}</Text>
                    <Text style={this.style().text}>{userDTO.name}</Text>
                    <Text style={this.style().text}>{userDTO.email}</Text>
                    <Text style={this.style().text}>{userDTO.userStatusTitle}</Text>
                    <Text style={this.style().text}>{modifDateAsString}</Text>
                    <TouchableWithoutFeedback style={{ alignItems: 'flex-start' }} onPress={() => { this.editField(user) }} >
                        <View style={{ alignItems: 'flex-start' }} >
                            <Image source={require('./assets/edit.png')} style={{ height: 24, width: 24, alignItems: 'flex-start' }} resizeMode="contain"></Image>
                        </View>
                    </TouchableWithoutFeedback>
                </View>
            )
        });
        this.setField({ datas: datas });
        this.setField({ rows: rows });
        this.setField({ initialData: datas.slice(0) });
        this.setField({ initialRow: rows });
        this.setField({ tableHeaders: ['ID', 'NUMBER', 'DATE CREATION', 'NOMS', 'MAIL', 'STATUS', 'MODIFICATION', 'EDIT'] });
        this.setField({ users: users });
        this.setField({ isLoading: false });
        this.setField({ nbPage: ret.totalPage });
    }

    editField(user: any) {
        //if(this.RoleManager.isAdmin || this.RoleManager.isEditor)
        //{
        let myUser = this.state().users.find((userFull) => userFull.id === user.id);
        if (myUser == null) {
            this.NavigationManager.navigate('/dashboard/users/edit', true, user);
        } else {
            this.NavigationManager.navigate('/dashboard/users/edit', true, myUser);
        }
        //}else{
        //  alert("No permission")
        //}
    }

    componentWillUnmount() {
        this.StateManager.addState(StateManagerConst.USER, this.state());
    }

    handleResetStepView() {
        this.setField({ resetStepView: false });
    }

    handleStatusChanged(value: any) {
        this.setField({ selectedStatus: value }, () => {
            this.handleResearching(this.state().text);
        });
    }

    onPageChange(page: number) {
        let pageNumber = page + 1;
        this.state().currentPage = pageNumber;
        let pageString = pageNumber.toString();
        localStorage.setItem("userPage", pageString);
        this.refresh()
    }

    resetTable() {
        if (this.state().selectedStatus.title == "ALL" && this.state().text == "") {
            this.resetResearch();
        }
    }

    pushRows(datasToPush: any[]) {
        let rows: any[] = [];
        datasToPush.forEach(user => {
            this.pushRow(user, rows);
        });

        this.setField({ rows: rows, datas: datasToPush, resetStepView: true });
    }

    pushRow(user: any, rowsToPush: any[]) {
        var date = new Date(user.formCompletionDate);
        var dateAsString = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " " +
            date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

        var modifDate = new Date(user.lastModificationDate);
        var modifDateAsString = modifDate.getDate() + "-" + (modifDate.getMonth() + 1) + "-" + modifDate.getFullYear() + " " +
            modifDate.getHours() + ":" + modifDate.getMinutes() + ":" + modifDate.getSeconds();

        rowsToPush.push(
            <View>
                <Text style={this.style().text}>{user.id}</Text>
                <Text style={this.style().text}>{user.clientNumber}</Text>
                <Text style={this.style().text}>{dateAsString}</Text>
                <Text style={this.style().text}>{user.name}</Text>
                <Text style={this.style().text}>{user.email}</Text>
                <Text style={this.style().text}>{user.userStatusTitle}</Text>
                <Text style={this.style().text}>{modifDateAsString}</Text>
                <TouchableWithoutFeedback onPress={() => { this.editField(user) }} >
                    <View>
                        <Image source={require('./assets/edit.png')} style={{ height: 24, width: 24 }} resizeMode="contain"></Image>
                    </View>
                </TouchableWithoutFeedback>


            </View>
        )
    }

    handlingClose() {
        this.state().isResearching = false;
        this.textResearch = "";
        this.onPageChange(0);
    }

    resetResearch() {
        this.setField({ datas: this.state().initialData.slice(0) });
        this.setField({ rows: this.state().initialRow.slice(0) });
        this.setField({ selectedStatus: "ALL" });
    }

    handleResearching(searchText: string) {
        this.setField({ isLoading: true, isResearching: true, text: searchText });
        this.textResearch = searchText;

        if (this.interval) {
            clearInterval(this.interval);
        }
        this.interval = setTimeout(() => {
            this.onPageChange(0)
        }, 1000)
    }

    exportUsers() {
        this.setField({ isExporting: true });
        this.usersOperation.export(this.state().selectedStatus.title, this.textResearch, true, (userToExport: any) => {

            var headers = ['guid', 'id', 'Date de création du compte', 'Email', 'Prénom', 'Nom', 'Langue', 'Id Recto', 'Id verso', 'Domiciliation', 'Banque', 'Civilité', 'Nationalité', 'Rue', 'Numéro', 'Boite', 'Code postal', 'Localité ', 'Pays', 'Date de naissance', 'Lieu de naissance',
                'Pays de naissance', 'Type de pièce identité', 'Numéro de pièce identité', 'Validité pièce identité', 'Lieu émission', 'Numéro national', 'Téléphone', 'Statut civil', 'Régime matrimonial',
                'Nom du conjoint', 'Statut professionnel', 'Secteur d activité', 'BCE/TVA', 'Statut', 'Résidence fiscale étranger', 'PPE', 'FATCA', 'IBAN',
                'BIC', 'Comment ais-je entendu parler de BeeBonds', 'ASP User ID', 'Numéro de client', 'Averti', 'Date de modification'];
            var jsonContent: any[] = [];
            userToExport.forEach((value: any) => {
                var element = "{";
                var row = "";

                var hasResidence = (value.hasFiscalResidence) ? "OUI" : "NON";
                var ppe = (value.ppe) ? "OUI" : "NON";
                var fatca = (value.fatca) ? "OUI" : "NON";

                var parts = value.birthDate.replace("/", "-").replace("/", "-").split("-");
                var birthDate = new Date(parseInt(parts[2], 10),
                    parseInt(parts[1], 10) - 1,
                    parseInt(parts[0], 10));
                var monthBirth = (birthDate.getMonth() + 1).toString();
                if (birthDate.getMonth() + 1 < 10)
                    monthBirth = "0" + monthBirth;
                var dayBirth = (birthDate.getDate()).toString();
                if (birthDate.getDate() < 10)
                    dayBirth = "0" + dayBirth;
                var birthDateAsString = birthDate.getFullYear() + '' + monthBirth + '' + dayBirth;

                var partsValidity = value.birthDate.replace("/", "-").replace("/", "-").split("-");
                var validityDate = new Date(parseInt(partsValidity[2], 10),
                    parseInt(partsValidity[1], 10) - 1,
                    parseInt(partsValidity[0], 10));
                var monthValidity = (validityDate.getMonth() + 1).toString();
                if (validityDate.getMonth() + 1 < 10)
                    monthValidity = "0" + monthValidity;
                var dayValidity = (validityDate.getDate()).toString();
                if (validityDate.getDate() < 10)
                    dayValidity = "0" + dayValidity;
                var validityDateAsString = validityDate.getFullYear() + '' + monthValidity + '' + dayValidity;


                var modifDate = new Date(value.lastModificationDate);
                var month = (modifDate.getMonth() + 1).toString();
                if (modifDate.getMonth() + 1 < 10)
                    month = "0" + month;
                var day = (modifDate.getDate()).toString();
                if (modifDate.getDate() < 10)
                    day = "0" + day;
                var modifDateAsString = modifDate.getFullYear() + '' + month + '' + day;

                var creaDate = new Date(value.formCompletionDate);
                var creaMonth = (creaDate.getMonth() + 1).toString();
                if (creaDate.getMonth() + 1 < 10)
                    creaMonth = "0" + creaMonth;
                var creaDay = (creaDate.getDate()).toString();
                if (creaDate.getDate() < 10)
                    creaDay = "0" + creaDay;
                var creaDateAsString = creaDate.getFullYear() + '' + creaMonth + '' + creaDay;

                var identityPieceNumber = (value.identityPieceNumber == null) ? "" : value.identityPieceNumber.replace("-", "").replace("-", "").replace(".", "").replace(".", "").replace(".", "");

                var nationalNumber = (value.nationalNumber == null) ? "" : value.nationalNumber.replace("-", "").replace("-", "").replace(".", "").replace(".", "").replace(".", "");

                row += JSON.stringify(headers[0]) + ":" + JSON.stringify(value.aspNetUserId);
                row += ",";
                row += JSON.stringify(headers[1]) + ":" + JSON.stringify(value.id);
                row += ",";
                row += JSON.stringify(headers[2]) + ":" + JSON.stringify(creaDateAsString);
                row += ",";
                row += JSON.stringify(headers[3]) + ":" + JSON.stringify(value.email);
                row += ",";
                row += JSON.stringify(headers[4]) + ":" + JSON.stringify(value.firstName);
                row += ",";
                row += JSON.stringify(headers[5]) + ":" + JSON.stringify(value.lastName);
                row += ",";
                row += JSON.stringify(headers[6]) + ":" + JSON.stringify(value.language);
                row += ",";
                row += JSON.stringify(headers[7]) + ":" + JSON.stringify(value.idRectoUrl);
                row += ",";
                row += JSON.stringify(headers[8]) + ":" + JSON.stringify(value.idVersoUrl);
                row += ",";
                row += JSON.stringify(headers[9]) + ":" + JSON.stringify(value.domiciliationUrl);
                row += ",";
                row += JSON.stringify(headers[10]) + ":" + JSON.stringify(value.proofOfBank);
                row += ",";
                row += JSON.stringify(headers[11]) + ":" + JSON.stringify(value.civility);
                row += ",";
                row += JSON.stringify(headers[12]) + ":" + JSON.stringify(value.nationality);
                row += ",";
                row += JSON.stringify(headers[13]) + ":" + JSON.stringify(value.address.street);
                row += ",";
                row += JSON.stringify(headers[14]) + ":" + JSON.stringify(value.address.number);
                row += ",";
                row += JSON.stringify(headers[15]) + ":" + JSON.stringify(value.address.box);
                row += ",";
                row += JSON.stringify(headers[16]) + ":" + JSON.stringify(value.address.postCode);
                row += ",";
                row += JSON.stringify(headers[17]) + ":" + JSON.stringify(value.address.locality);
                row += ",";
                row += JSON.stringify(headers[18]) + ":" + JSON.stringify(value.address.country);
                row += ",";
                row += JSON.stringify(headers[19]) + ":" + JSON.stringify(birthDateAsString);
                row += ",";
                row += JSON.stringify(headers[20]) + ":" + JSON.stringify(value.birthLocation);
                row += ",";
                row += JSON.stringify(headers[21]) + ":" + JSON.stringify(value.birthCountry);
                row += ",";
                row += JSON.stringify(headers[22]) + ":" + JSON.stringify(value.identityPieceType);
                row += ",";
                row += JSON.stringify(headers[23]) + ":" + JSON.stringify(identityPieceNumber);
                row += ",";
                row += JSON.stringify(headers[24]) + ":" + JSON.stringify(validityDateAsString);
                row += ",";
                row += JSON.stringify(headers[25]) + ":" + JSON.stringify(value.identityPieceAuthority);
                row += ",";
                row += JSON.stringify(headers[26]) + ":" + JSON.stringify(nationalNumber);
                row += ",";
                row += JSON.stringify(headers[27]) + ":" + JSON.stringify(value.phoneNumber);
                row += ",";
                row += JSON.stringify(headers[28]) + ":" + JSON.stringify(value.civilStatus);
                row += ",";
                row += JSON.stringify(headers[29]) + ":" + JSON.stringify(value.matrimonialRegime);
                row += ",";
                row += JSON.stringify(headers[30]) + ":" + JSON.stringify(value.conjointName);
                row += ",";
                row += JSON.stringify(headers[31]) + ":" + JSON.stringify(value.professionalStatus);
                row += ",";
                row += JSON.stringify(headers[32]) + ":" + JSON.stringify(value.activitySector);
                row += ",";
                row += JSON.stringify(headers[33]) + ":" + JSON.stringify(value.bceOrTva);
                row += ",";
                row += JSON.stringify(headers[34]) + ":" + JSON.stringify(value.userStatus.title);
                row += ",";
                row += JSON.stringify(headers[35]) + ":" + JSON.stringify(hasResidence);
                row += ",";
                row += JSON.stringify(headers[36]) + ":" + JSON.stringify(ppe);
                row += ",";
                row += JSON.stringify(headers[37]) + ":" + JSON.stringify(fatca);
                row += ",";
                row += JSON.stringify(headers[38]) + ":" + JSON.stringify(value.iban);
                row += ",";
                row += JSON.stringify(headers[39]) + ":" + JSON.stringify(value.bic);
                row += ",";
                row += JSON.stringify(headers[40]) + ":" + JSON.stringify(value.howDoIHeardAboutBeeBonds);
                row += ",";
                row += JSON.stringify(headers[41]) + ":" + JSON.stringify(value.aspNetUserId);
                row += ",";
                row += JSON.stringify(headers[42]) + ":" + JSON.stringify(value.clientNumber);
                row += ",";
                row += JSON.stringify(headers[43]) + ":" + JSON.stringify(value.isAverti);
                row += ",";
                row += JSON.stringify(headers[44]) + ":" + JSON.stringify(modifDateAsString);
                element = element + row;
                element += "}";
                jsonContent.push(JSON.parse(element));
            });
            var wb = XLSX.utils.book_new();
            var o = XLSX.utils.json_to_sheet(jsonContent, { header: headers });
            XLSX.utils.book_append_sheet(wb, o, "Users");
            XLSX.writeFile(wb, "BeeBondsUsers.xlsx");
            this.setField({ isExporting: false });
        });
    }

    async exportUsersVBOUT(page = 1, jsonContent: any[] = []) {
        this.setField({ isExporting: true, vbexport: true });
        var headers = ['Nom', 'Prénom', 'Téléphone', 'Email', 'Langue', 'Nombre de tests', 'Montant total investi', 'Nombre d’investissements', 'Date de création du compte user', 'Date de création du compte investisseur', 'Utilisateur non averti (Test réussi)', 'Statut de l’investisseur', 'Date du premier investissement', 'Date du dernier investissement', 'Dernière connexion', 'Formulaire investisseur Averti', 'Comment a entendu de BeeBonds?'];
        await this.usersOperation.exportVBout(page, 20, this.state().selectedStatus.title, this.textResearch, (userToExport: any) => {
            let pageIncrease = page + 1;
            let exportPercent = pageIncrease / this.state().nbPage * 100;
            if (exportPercent > 100)
                exportPercent = 100;
            this.setField({ exportPage: pageIncrease, exportPercent: exportPercent.toFixed(2) });
            userToExport.forEach((value: any) => {
                var element = "{";
                var row = "";

                var parts = value.birthDate.replace("/", "-").replace("/", "-").split("-");
                var birthDate = new Date(parseInt(parts[2], 10),
                    parseInt(parts[1], 10) - 1,
                    parseInt(parts[0], 10));
                var monthBirth = (birthDate.getMonth() + 1).toString();
                if (birthDate.getMonth() + 1 < 10)
                    monthBirth = "0" + monthBirth;
                var dayBirth = (birthDate.getDate()).toString();
                if (birthDate.getDate() < 10)
                    dayBirth = "0" + dayBirth;

                var partsValidity = value.birthDate.replace("/", "-").replace("/", "-").split("-");
                var validityDate = new Date(parseInt(partsValidity[2], 10),
                    parseInt(partsValidity[1], 10) - 1,
                    parseInt(partsValidity[0], 10));
                var monthValidity = (validityDate.getMonth() + 1).toString();
                if (validityDate.getMonth() + 1 < 10)
                    monthValidity = "0" + monthValidity;
                var dayValidity = (validityDate.getDate()).toString();
                if (validityDate.getDate() < 10)
                    dayValidity = "0" + dayValidity;

                var lastConnectionDate = new Date(value.lastConnectionDate);

                var creaDate = new Date(value.creationDate);
                var creaMonth = (creaDate.getMonth() + 1).toString();
                if (creaDate.getMonth() + 1 < 10)
                    creaMonth = "0" + creaMonth;
                var creaDay = (creaDate.getDate()).toString();
                if (creaDate.getDate() < 10)
                    creaDay = "0" + creaDay;
                var creaDateAsString = creaDate.getFullYear() + '' + creaMonth + '' + creaDay;

                var creaDateInvestor = new Date(value.formCompletionDate);
                var creaDateInvestorMonth = (creaDateInvestor.getMonth() + 1).toString();
                if (creaDateInvestor.getMonth() + 1 < 10)
                    creaDateInvestorMonth = "0" + creaDateInvestorMonth;
                var creaDateInvestorDay = (creaDateInvestor.getDate()).toString();
                if (creaDateInvestor.getDate() < 10)
                    creaDateInvestorDay = "0" + creaDateInvestor;
                var creaDateInvestorAsString = creaDateInvestor.getFullYear() + '' + creaDateInvestorMonth + '' + creaDateInvestorDay;

                var formAvesti = "NON";
                if (value.isAverti)
                    formAvesti = "OUI";

                row += JSON.stringify(headers[0]) + ":" + JSON.stringify(value.lastName);
                row += ",";
                row += JSON.stringify(headers[1]) + ":" + JSON.stringify(value.firstName);
                row += ",";
                row += JSON.stringify(headers[2]) + ":" + JSON.stringify(value.phoneNumber);
                row += ",";
                row += JSON.stringify(headers[3]) + ":" + JSON.stringify(value.email);
                row += ",";
                row += JSON.stringify(headers[4]) + ":" + JSON.stringify(value.language);
                row += ",";
                row += JSON.stringify(headers[5]) + ":" + JSON.stringify(value.numberOfFormsAttempts);
                row += ",";
                row += JSON.stringify(headers[6]) + ":" + JSON.stringify(value.totalInvestments);
                row += ",";
                row += JSON.stringify(headers[7]) + ":" + JSON.stringify(value.numberInvestments);
                row += ",";
                row += JSON.stringify(headers[8]) + ":" + JSON.stringify(creaDateAsString);
                row += ",";
                row += JSON.stringify(headers[9]) + ":" + JSON.stringify(creaDateInvestorAsString);
                row += ",";
                row += JSON.stringify(headers[10]) + ":" + JSON.stringify(value.hasSucceededTests);
                row += ",";
                row += JSON.stringify(headers[11]) + ":" + JSON.stringify(value.userStatus.title);  
                row += ",";
                row += JSON.stringify(headers[12]) + ":" + JSON.stringify(value.firsttInvestmentDate);
                row += ",";
                row += JSON.stringify(headers[13]) + ":" + JSON.stringify(value.lastInvestmentDate);
                row += ",";
                row += JSON.stringify(headers[14]) + ":" + JSON.stringify(lastConnectionDate);
                row += ",";
                row += JSON.stringify(headers[15]) + ":" + JSON.stringify(formAvesti);
                row += ",";
                row += JSON.stringify(headers[16]) + ":" + JSON.stringify(value.howDoIHeardAboutBeeBonds);
                element = element + row;
                element += "}";
                jsonContent.push(JSON.parse(element));
            });
            // Export finished
            if (userToExport.length == 0) {
                var wb = XLSX.utils.book_new();
                var o = XLSX.utils.json_to_sheet(jsonContent, { header: headers });
                XLSX.utils.book_append_sheet(wb, o, "Users");
                XLSX.writeFile(wb, "BeeBondsUsers.xlsx");
                this.setField({ isExporting: false, vbexport: false });
            }
            else {
                let pageIncrease = page + 1;
                let exportPercent = page / this.state().nbPage * 100;
                if (exportPercent > 100)
                    exportPercent = 100;
                this.setField({ exportPage: pageIncrease, exportPercent: exportPercent.toFixed(2) });
                this.exportUsersVBOUT(pageIncrease, jsonContent);
            }
        });
    }

    exportNonAvertisUsers() {

        this.setField({ isExporting: true });
        this.usersOperation.exportNotAvertis((userToExport: any) => {

            var headers = ['guid', 'id', 'Date de création du compte', 'Email', 'Prénom', 'Nom', 'Langue', 'Numéro de client', 'Averti', 'Date de modification',
                'Score Personal situation questionnaire 1', 'Score Questionnaire de connaissance 1', 'Score Objectifs investissement + situation 1',
                'Score Personal situation questionnaire 2', 'Score Questionnaire de connaissance 2', 'Score Objectifs investissement + situation 2',
                'Score Personal situation questionnaire 3', 'Score Questionnaire de connaissance 3', 'Score Objectifs investissement + situation 3',];
            var jsonContent: any[] = [];
            userToExport.forEach((value: any) => {
                var element = "{";
                var row = "";
                var parts = value.birthDate.replace("/", "-").replace("/", "-").split("-");
                var birthDate = new Date(parseInt(parts[2], 10),
                    parseInt(parts[1], 10) - 1,
                    parseInt(parts[0], 10));
                var monthBirth = (birthDate.getMonth() + 1).toString();
                if (birthDate.getMonth() + 1 < 10)
                    monthBirth = "0" + monthBirth;
                var dayBirth = (birthDate.getDate()).toString();
                if (birthDate.getDate() < 10)
                    dayBirth = "0" + dayBirth;

                var partsValidity = value.birthDate.replace("/", "-").replace("/", "-").split("-");
                var validityDate = new Date(parseInt(partsValidity[2], 10),
                    parseInt(partsValidity[1], 10) - 1,
                    parseInt(partsValidity[0], 10));
                var monthValidity = (validityDate.getMonth() + 1).toString();
                if (validityDate.getMonth() + 1 < 10)
                    monthValidity = "0" + monthValidity;
                var dayValidity = (validityDate.getDate()).toString();
                if (validityDate.getDate() < 10)
                    dayValidity = "0" + dayValidity;

                var modifDate = new Date(value.lastModificationDate);
                var month = (modifDate.getMonth() + 1).toString();
                if (modifDate.getMonth() + 1 < 10)
                    month = "0" + month;
                var day = (modifDate.getDate()).toString();
                if (modifDate.getDate() < 10)
                    day = "0" + day;
                var modifDateAsString = modifDate.getFullYear() + '' + month + '' + day;

                var creaDate = new Date(value.formCompletionDate);
                var creaMonth = (creaDate.getMonth() + 1).toString();
                if (creaDate.getMonth() + 1 < 10)
                    creaMonth = "0" + creaMonth;
                var creaDay = (creaDate.getDate()).toString();
                if (creaDate.getDate() < 10)
                    creaDay = "0" + creaDay;
                var creaDateAsString = creaDate.getFullYear() + '' + creaMonth + '' + creaDay;

                let form11 = value.formsUser.filter((x: any) => x.formsId == 3)[0];
                let form12 = value.formsUser.filter((x: any) => x.formsId == 4)[0];
                let form13 = value.formsUser.filter((x: any) => x.formsId == 5)[0];

                let form11Score = 0;
                let form12Score = 0;
                let form13Score = 0;

                if (form11)
                    form11Score = form11.score;
                if (form12)
                    form12Score = form12.score;
                if (form13)
                    form13Score = form13.score;

                let form21 = value.formsUser.filter((x: any) => x.formsId == 3)[1];
                let form22 = value.formsUser.filter((x: any) => x.formsId == 4)[1];
                let form23 = value.formsUser.filter((x: any) => x.formsId == 5)[1];

                let form21Score = 0;
                let form22Score = 0;
                let form23Score = 0;

                if (form21)
                    form21Score = form21.score;
                if (form22)
                    form22Score = form22.score;
                if (form23)
                    form23Score = form23.score;

                let form31 = value.formsUser.filter((x: any) => x.formsId == 3)[2];
                let form32 = value.formsUser.filter((x: any) => x.formsId == 4)[2];
                let form33 = value.formsUser.filter((x: any) => x.formsId == 5)[2];

                let form31Score = 0;
                let form32Score = 0;
                let form33Score = 0;

                if (form31)
                    form31Score = form31.score;
                if (form32)
                    form32Score = form32.score;
                if (form33)
                    form33Score = form33.score;


                row += JSON.stringify(headers[0]) + ":" + JSON.stringify(value.aspNetUserId);
                row += ",";
                row += JSON.stringify(headers[1]) + ":" + JSON.stringify(value.id);
                row += ",";
                row += JSON.stringify(headers[2]) + ":" + JSON.stringify(creaDateAsString);
                row += ",";
                row += JSON.stringify(headers[3]) + ":" + JSON.stringify(value.email);
                row += ",";
                row += JSON.stringify(headers[4]) + ":" + JSON.stringify(value.firstName);
                row += ",";
                row += JSON.stringify(headers[5]) + ":" + JSON.stringify(value.lastName);
                row += ",";
                row += JSON.stringify(headers[6]) + ":" + JSON.stringify(value.language);
                row += ",";
                row += JSON.stringify(headers[7]) + ":" + JSON.stringify(value.clientNumber);
                row += ",";
                row += JSON.stringify(headers[8]) + ":" + JSON.stringify(value.isAverti);
                row += ",";
                row += JSON.stringify(headers[9]) + ":" + JSON.stringify(modifDateAsString);
                row += ",";
                row += JSON.stringify(headers[10]) + ":" + JSON.stringify(form11Score);
                row += ",";
                row += JSON.stringify(headers[11]) + ":" + JSON.stringify(form12Score);
                row += ",";
                row += JSON.stringify(headers[12]) + ":" + JSON.stringify(form13Score);
                row += ",";
                row += JSON.stringify(headers[13]) + ":" + JSON.stringify(form21Score);
                row += ",";
                row += JSON.stringify(headers[14]) + ":" + JSON.stringify(form22Score);
                row += ",";
                row += JSON.stringify(headers[15]) + ":" + JSON.stringify(form23Score);
                row += ",";
                row += JSON.stringify(headers[16]) + ":" + JSON.stringify(form31Score);
                row += ",";
                row += JSON.stringify(headers[17]) + ":" + JSON.stringify(form32Score);
                row += ",";
                row += JSON.stringify(headers[18]) + ":" + JSON.stringify(form33Score);
                element = element + row;
                element += "}";
                jsonContent.push(JSON.parse(element));
            });
            var wb = XLSX.utils.book_new();
            var o = XLSX.utils.json_to_sheet(jsonContent, { header: headers });
            XLSX.utils.book_append_sheet(wb, o, "Users");
            XLSX.writeFile(wb, "BeeBondsUsers.xlsx");
            this.setField({ isExporting: false });
        });


    }

    exportAvertiNullUsers() {
        this.setField({ isExporting: true });
        this.usersOperation.exportAvertisNull((userToExport: any) => {

            var headers = ['guid', 'id', 'Date de création du compte', 'Email', 'Prénom', 'Nom', 'Langue', 'Numéro de client', 'Date de modification'];
            var jsonContent: any[] = [];
            userToExport.forEach((value: any) => {
                var element = "{";
                var row = "";
                var parts = value.birthDate.replace("/", "-").replace("/", "-").split("-");
                var birthDate = new Date(parseInt(parts[2], 10),
                    parseInt(parts[1], 10) - 1,
                    parseInt(parts[0], 10));
                var monthBirth = (birthDate.getMonth() + 1).toString();
                if (birthDate.getMonth() + 1 < 10)
                    monthBirth = "0" + monthBirth;
                var dayBirth = (birthDate.getDate()).toString();
                if (birthDate.getDate() < 10)
                    dayBirth = "0" + dayBirth;

                var partsValidity = value.birthDate.replace("/", "-").replace("/", "-").split("-");
                var validityDate = new Date(parseInt(partsValidity[2], 10),
                    parseInt(partsValidity[1], 10) - 1,
                    parseInt(partsValidity[0], 10));
                var monthValidity = (validityDate.getMonth() + 1).toString();
                if (validityDate.getMonth() + 1 < 10)
                    monthValidity = "0" + monthValidity;
                var dayValidity = (validityDate.getDate()).toString();
                if (validityDate.getDate() < 10)
                    dayValidity = "0" + dayValidity;

                var modifDate = new Date(value.lastModificationDate);
                var month = (modifDate.getMonth() + 1).toString();
                if (modifDate.getMonth() + 1 < 10)
                    month = "0" + month;
                var day = (modifDate.getDate()).toString();
                if (modifDate.getDate() < 10)
                    day = "0" + day;
                var modifDateAsString = modifDate.getFullYear() + '' + month + '' + day;

                var creaDate = new Date(value.formCompletionDate);
                var creaMonth = (creaDate.getMonth() + 1).toString();
                if (creaDate.getMonth() + 1 < 10)
                    creaMonth = "0" + creaMonth;
                var creaDay = (creaDate.getDate()).toString();
                if (creaDate.getDate() < 10)
                    creaDay = "0" + creaDay;
                var creaDateAsString = creaDate.getFullYear() + '' + creaMonth + '' + creaDay;

                row += JSON.stringify(headers[0]) + ":" + JSON.stringify(value.aspNetUserId);
                row += ",";
                row += JSON.stringify(headers[1]) + ":" + JSON.stringify(value.id);
                row += ",";
                row += JSON.stringify(headers[2]) + ":" + JSON.stringify(creaDateAsString);
                row += ",";
                row += JSON.stringify(headers[3]) + ":" + JSON.stringify(value.email);
                row += ",";
                row += JSON.stringify(headers[4]) + ":" + JSON.stringify(value.firstName);
                row += ",";
                row += JSON.stringify(headers[5]) + ":" + JSON.stringify(value.lastName);
                row += ",";
                row += JSON.stringify(headers[6]) + ":" + JSON.stringify(value.language);
                row += ",";
                row += JSON.stringify(headers[7]) + ":" + JSON.stringify(value.clientNumber);
                row += ",";
                row += JSON.stringify(headers[8]) + ":" + JSON.stringify(modifDateAsString);
                element = element + row;
                element += "}";
                jsonContent.push(JSON.parse(element));
            });
            var wb = XLSX.utils.book_new();
            var o = XLSX.utils.json_to_sheet(jsonContent, { header: headers });
            XLSX.utils.book_append_sheet(wb, o, "Users");
            XLSX.writeFile(wb, "BeeBondsUsers.xlsx");
            this.setField({ isExporting: false });
        });
    }

    checkIdentityValidity(sendMail: boolean) {
        this.setField((previousState) => previousState.isCheckingValidty = true)
        this.usersOperation.checkIdentityValidity(sendMail, (response: any[]) => {
            this.setField((previousState) => previousState.isCheckingValidty = false)
            this.showPopUp(<CheckIdentityValidityPopupView response={response} />
            )
        })
    }
}